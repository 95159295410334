import { storeToRefs } from 'pinia'
const { account } = storeToRefs(useUserStore())
function useBrandColor() {

  const color = computed(() => account.value?.metadata?.brand! || '#8A2BE2')

  const genTextColor = function (hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      // Counting the perceptive luminance
      // human eye favors green color... 
      const ratio = 1 - (0.299 * parseInt(result[1], 16) + 0.587 * parseInt(result[2], 16) + 0.114 * parseInt(result[3], 16)) / 255;
      return (ratio < 0.5 ? '#000' : '#fff');
    }

  }
  const colorStyle = computed(() => {
    return {
      backgroundColor: color.value,
      color: genTextColor(color.value)
    }
  })

  return {
    color,
    colorStyle
  }
}


export default createGlobalState(useBrandColor)